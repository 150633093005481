import React from "react";
import theme from "theme";
import { Theme, Text, Section, Icon, Box } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { MdLocationOn, MdPhone, MdEmail } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contact-us"} />
		<Helmet>
			<title>
				Favour
			</title>
			<meta name={"description"} content={"Де футбол оживає"} />
			<meta property={"og:title"} content={"Favour"} />
			<meta property={"og:description"} content={"Де футбол оживає"} />
			<meta property={"og:image"} content={"https://ua.skyifair.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://ua.skyifair.com/img/456236236.jpeg"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://ua.skyifair.com/img/456236236.jpeg"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://ua.skyifair.com/img/456236236.jpeg"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://ua.skyifair.com/img/456236236.jpeg"} />
			<link rel={"apple-touch-startup-image"} href={"https://ua.skyifair.com/img/456236236.jpeg"} />
			<meta name={"msapplication-TileImage"} content={"https://ua.skyifair.com/img/456236236.jpeg"} />
			
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 0px 0" sm-padding="40px 0">
			<Override slot="SectionContent" align-items="center" />
			<Text as="h2" font="--headline1" md-font="--headline2" margin="20px 0 0 0">
				Зв'яжіться з нами
			</Text>
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="center">
				Чи є у вас запити чи потрібна допомога? Наша доступна та добре обізнана команда легко доступна для надання допомоги.Не соромтеся зв’язатися з нами електронною поштою, телефоном або нашою контактною формою, і ми своєчасно відповімо вам.
			</Text>
		</Section>
		<Section padding="50px 0 220px 0" sm-padding="60px 0 60px 0">
			<Box display="grid" grid-template-columns="repeat(3, 1fr)" grid-gap="24px 4%" md-grid-template-columns="1fr">
				<Box justify-content="flex-start" flex-direction="column" display="flex">
					<Icon
						category="md"
						icon={MdLocationOn}
						margin="0px 0px 16px 0px"
						color="--dark"
						size="48px"
						padding="6px 6px 6px 6px"
					/>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Адреса
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--lead"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Боднаровка 15, Львів, 79000
					</Text>
				</Box>
				<Box justify-content="flex-start" flex-direction="column" display="flex">
					<Icon
						category="md"
						icon={MdPhone}
						margin="0px 0px 16px 0px"
						color="--dark"
						size="48px"
						padding="6px 6px 6px 6px"
					/>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Телефон
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						050 315 2222
					</Text>
				</Box>
				<Box justify-content="flex-start" flex-direction="column" display="flex">
					<Icon
						category="md"
						icon={MdEmail}
						margin="0px 0px 16px 0px"
						color="--dark"
						size="48px"
						padding="6px 6px 6px 6px"
					/>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Email
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--lead"
						lg-text-align="left"
						flex="1 0 auto"
					>
						manage@ua.skyifair.com
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6530dc90e2dd3a0022211ece"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});